<template>
  <div>
    <nav class="my-navbar navbar navbar-light navbar-expand-md nav-block">
      <div>
        <Logo v-bind:isCustomBtn="customLetsTalk || customBiography" />
      </div>
      <div v-if="!customLetsTalk && !customBiography" class="left-nav">
        <a class="navbar-toggler ml-1 toggle-side">
          <svg
            id="line-menu_resp"
            style="height: 25px;transform: translate(0px, -5px);"
            width="49"
            height="31"
            viewBox="0 0 49 31"
          >
            <g transform="translate(2 2)">
              <line
                class="bar"
                id="menu-btn-line-top"
                style="fill: none;stroke: #212121;stroke-linecap: round;stroke-width: 4px;"
                x2="27"
              />
              <line
                class="bar"
                id="menu-btn-line-middle"
                style="fill: none;stroke: #212121;stroke-linecap: round;stroke-width: 4px;"
                x2="31"
              />
              <line
                class="bar"
                id="menu-btn-line-middle_2"
                style="fill: none;stroke: #212121;stroke-linecap: round;stroke-width: 4px;"
                x2="31"
              />
              <line
                class="bar"
                id="menu-btn-line-bottom"
                style="fill: none;stroke: #212121;stroke-linecap: round;stroke-width: 4px;"
                x2="35"
              />
            </g>
          </svg>
        </a>
        <div class="body-container">
          <div class="side-menu">
            <svg
              style="position: absolute;width: 100vh;margin: 0;"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 712.772 896"
            >
              <g>
                <rect
                  style="height: 100%;fill: rgb(197, 67, 66);width: 100%;"
                />
                <g id="bg-menu__logo" style="opacity: 0.048;">
                  <path
                    style="fill: #fff;"
                    d="M1270.78,877.523V747.82H622.28v518.8h389.1v-129.7h-259.4v-259.4Z"
                    transform="translate(-558.008 -747.82)"
                  />
                  <path
                    style="fill: #fff;"
                    d="M1005.682,1158.57h-259.4v129.7h259.4v259.4H486.88v129.7h648.5v-518.8Z"
                    transform="translate(-486.88 -963.593)"
                  />
                </g>
              </g>
            </svg>
            <div class="header">
              <div class="menu-title">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="139.176"
                  height="35.826"
                  viewBox="0 0 139.176 35.826"
                >
                  <g transform="translate(-162 -99)">
                    <g transform="translate(162 99)">
                      <g transform="translate(0)">
                        <path
                          style="fill: #fff;"
                          d="M204.912,89.982v-6.53h-32.59v26.121h19.554v-6.53H178.84V89.982Z"
                          transform="translate(-169.086 -83.452)"
                        />
                        <path
                          style="fill: #fff;"
                          d="M164.447,186.432H151.411v6.53h13.036v13.061H138.375v6.53h32.59V186.432Z"
                          transform="translate(-138.375 -176.727)"
                        />
                      </g>
                    </g>
                    <g transform="translate(210.375 109.125)">
                      <path
                        style="fill: #fff;"
                        d="M72.381,62.942a3.849,3.849,0,0,0-.572-.426,6.985,6.985,0,0,0-1.009-.527,8.748,8.748,0,0,0-1.289-.437,5.64,5.64,0,0,0-1.413-.18,3.167,3.167,0,0,0-1.906.471,1.558,1.558,0,0,0-.628,1.323,1.36,1.36,0,0,0,.235.819,2.033,2.033,0,0,0,.684.572,5.736,5.736,0,0,0,1.132.449q.684.2,1.581.426,1.166.314,2.119.673a5.751,5.751,0,0,1,1.615.9,3.708,3.708,0,0,1,1.02,1.289,4.28,4.28,0,0,1,.359,1.85,4.609,4.609,0,0,1-.482,2.186,4.055,4.055,0,0,1-1.3,1.469,5.653,5.653,0,0,1-1.884.818,9.47,9.47,0,0,1-2.231.258,11.984,11.984,0,0,1-3.543-.538,10.934,10.934,0,0,1-3.14-1.525L63.1,70.141a4.292,4.292,0,0,0,.729.527,11.044,11.044,0,0,0,1.244.65,9.652,9.652,0,0,0,1.592.549,7.183,7.183,0,0,0,1.794.224q2.556,0,2.556-1.637a1.343,1.343,0,0,0-.292-.875,2.475,2.475,0,0,0-.83-.628,7.951,7.951,0,0,0-1.3-.493q-.763-.224-1.7-.493a14.475,14.475,0,0,1-1.985-.684,5.21,5.21,0,0,1-1.4-.875,3.274,3.274,0,0,1-.841-1.166,4.034,4.034,0,0,1-.28-1.581,4.946,4.946,0,0,1,.448-2.153,4.349,4.349,0,0,1,1.245-1.57,5.64,5.64,0,0,1,1.85-.953,7.652,7.652,0,0,1,2.265-.325,8.794,8.794,0,0,1,3.095.527,11.539,11.539,0,0,1,2.467,1.245Z"
                        transform="translate(-61.729 -58.659)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M204.863,72.861v2.713H193.807V59.652h10.854v2.714H196.9V66.2h6.705v2.512H196.9v4.149Z"
                        transform="translate(-178.894 -59.54)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M302.637,59.652l3.924,7.67,3.992-7.67h3.341l-5.786,10.361v5.562h-3.072V69.968l-5.763-10.316Z"
                        transform="translate(-272.452 -59.54)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M433.211,59.652l4.306,12.043,4.261-12.043h3.252l-6.212,15.922h-2.6l-6.257-15.922Z"
                        transform="translate(-388.382 -59.54)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M572.58,74.805a7.231,7.231,0,0,1-3.173-.684,7.787,7.787,0,0,1-2.444-1.816,8.424,8.424,0,0,1-2.142-5.573,8,8,0,0,1,.594-3.05,8.418,8.418,0,0,1,1.637-2.579,7.814,7.814,0,0,1,2.478-1.783,7.359,7.359,0,0,1,3.117-.662,6.992,6.992,0,0,1,3.151.706,7.873,7.873,0,0,1,2.433,1.85,8.521,8.521,0,0,1,1.57,2.6,8.19,8.19,0,0,1,.561,2.96,8.054,8.054,0,0,1-.583,3.039,8.255,8.255,0,0,1-1.615,2.557,7.767,7.767,0,0,1-2.467,1.772A7.355,7.355,0,0,1,572.58,74.805Zm-4.62-8.073a6.4,6.4,0,0,0,.314,2,5.325,5.325,0,0,0,.908,1.7,4.512,4.512,0,0,0,1.458,1.189,4.515,4.515,0,0,0,3.947-.011,4.52,4.52,0,0,0,1.458-1.222,5.449,5.449,0,0,0,.9-1.715,6.372,6.372,0,0,0,.3-1.94,6.212,6.212,0,0,0-.325-2,5.512,5.512,0,0,0-.919-1.7,4.392,4.392,0,0,0-1.458-1.177,4.541,4.541,0,0,0-3.935.022,4.425,4.425,0,0,0-1.458,1.211,5.435,5.435,0,0,0-.886,1.7A6.373,6.373,0,0,0,567.961,66.732Z"
                        transform="translate(-508.017 -58.659)"
                      />
                      <path
                        style="fill: #fff;"
                        d="M736.5,75.574V65.034l-4.1,7.849h-1.682l-4.1-7.849v10.54h-3.095V59.652h3.319l4.709,9.06,4.754-9.06h3.3V75.574Z"
                        transform="translate(-648.793 -59.54)"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div class="menu-items">
                <div class="menu-item">
                  <a
                    v-if="!customLetsTalk && !customBiography"
                    class="menu-link"
                    id="home_"
                    >HOME</a
                  >
                </div>
                <div class="menu-item">
                  <a
                    v-if="!customLetsTalk && !customBiography"
                    class="menu-link"
                    id="biography_"
                    >ABOUT US</a
                  >
                </div>
                <div class="menu-item">
                  <a
                    v-if="!customLetsTalk && !customBiography"
                    class="menu-link"
                    id="projects_"
                    >PROJECTS</a
                  >
                </div>
                <div class="menu-item">
                  <a
                    v-if="!customLetsTalk && !customBiography"
                    class="menu-link"
                    id="contacts_"
                    >CONTACTS</a
                  >
                </div>
                <div class="menu-item">
                  <router-link
                    v-if="!customLetsTalk && !customBiography"
                    to="/lets-talk"
                    class="menu-item__lets-talk"
                    >Let’s talk!</router-link
                  >
                  <div
                    class="menu-item__lets-talk_ menu-item__lets-talk invisible anim-none"
                    style="display:none"
                  >
                    Let’s talk!
                  </div>
                </div>
              </div>
              <div class="menu-items__socialmedia">
                <div class="menu-item__fb">
                  <a href="https://www.fb.com/seyvom/" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                    >
                      <path
                        style=" fill: #fff;fill-rule: evenodd;"
                        d="M33.624,0H4.375A3.386,3.386,0,0,0,1,3.376V32.625A3.386,3.386,0,0,0,4.375,36H33.624A3.386,3.386,0,0,0,37,32.625V3.375A3.386,3.386,0,0,0,33.625,0ZM21.294,10.345h3.83V5.751h-3.83a5.367,5.367,0,0,0-5.356,5.359v2.3H12.876V18h3.062V30.249h4.594V18h3.826l.766-4.594H20.531v-2.3a.775.775,0,0,1,.762-.766h0Z"
                        transform="translate(-1)"
                      />
                    </svg>
                  </a>
                </div>
                <div class="menu-item__insta">
                  <a href="https://www.instagram.com/seyvom/" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                    >
                      <path
                        style="fill: #fff;"
                        d="M28.174,36H7.826A7.849,7.849,0,0,1,0,28.174V7.826A7.849,7.849,0,0,1,7.826,0H28.174A7.849,7.849,0,0,1,36,7.826V28.174A7.849,7.849,0,0,1,28.174,36ZM18,4.7c-3.606,0-4.069,0-5.478.08a10.521,10.521,0,0,0-3.21.623A5.665,5.665,0,0,0,6.964,6.964,8.835,8.835,0,0,0,5.4,9.311a10.521,10.521,0,0,0-.623,3.21C4.7,13.931,4.7,14.395,4.7,18s0,4.068.08,5.478a10.521,10.521,0,0,0,.623,3.21,5.665,5.665,0,0,0,1.565,2.348A7.676,7.676,0,0,0,9.311,30.6a10.538,10.538,0,0,0,3.21.624c1.409.079,1.873.079,5.478.079s4.068,0,5.478-.079a10.538,10.538,0,0,0,3.21-.624,5.665,5.665,0,0,0,2.348-1.565A7.676,7.676,0,0,0,30.6,26.689a10.538,10.538,0,0,0,.624-3.21c.079-1.409.079-1.873.079-5.478s0-4.069-.079-5.478a10.538,10.538,0,0,0-.624-3.21,5.665,5.665,0,0,0-1.565-2.348A8.835,8.835,0,0,0,26.689,5.4a10.521,10.521,0,0,0-3.21-.623C22.069,4.7,21.605,4.7,18,4.7Zm5.322,24.261H18c-3.615,0-3.993,0-5.4-.08a8.669,8.669,0,0,1-2.5-.465l-.008,0a4.177,4.177,0,0,1-1.566-1.018,4.151,4.151,0,0,1-1.015-1.565,8.579,8.579,0,0,1-.471-2.5V18c0-3.615,0-3.993.08-5.4a8.687,8.687,0,0,1,.463-2.491l0-.016A4.177,4.177,0,0,1,8.609,8.529a4.151,4.151,0,0,1,1.565-1.015,8.579,8.579,0,0,1,2.5-.471H18c3.614,0,3.992,0,5.4.08a8.724,8.724,0,0,1,2.489.461l.019.007a4.488,4.488,0,0,1,2.58,2.583,8.578,8.578,0,0,1,.471,2.5V18c0,3.614,0,3.992-.08,5.4a8.654,8.654,0,0,1-.465,2.5l0,.009a4.488,4.488,0,0,1-2.583,2.58A8.578,8.578,0,0,1,23.322,28.957ZM18,10.957a7.014,7.014,0,1,0,4.989,2.054A7.006,7.006,0,0,0,18,10.957Zm7.043-1.566a1.565,1.565,0,1,0,1.565,1.566A1.568,1.568,0,0,0,25.044,9.391ZM18,22.7A4.7,4.7,0,1,1,22.7,18,4.7,4.7,0,0,1,18,22.7Z"
                      />
                    </svg>
                  </a>
                </div>
                <div class="menu-item__linkedin">
                  <a href="https://www.linkedin.com/company/seyvom" target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="36"
                      height="36"
                    >
                      <path
                        style="fill: #fff;"
                        d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link v-else to="/" class="btn-back btn-back-resp">
        <i class="fa fa-arrow-left back-arrow"></i>Back</router-link
      >
      <div
        class="navbar-collapse collapse justify-content-between align-items-center w-100"
        id="collapsingNavbar"
      >
        <ul class="navbar-nav mx-auto text-center navbar-links">
          <li class="nav-item">
            <a
              v-if="!customLetsTalk && !customBiography"
              class="nav-link link__text"
              id="biography"
              >ABOUT US</a
            >
          </li>
          <li class="nav-item link__middle">
            <a
              v-if="!customLetsTalk && !customBiography"
              class="nav-link link__text"
              id="projects"
              >PROJECTS</a
            >
          </li>
          <li class="nav-item">
            <a
              v-if="!customLetsTalk && !customBiography"
              class="nav-link link__text"
              id="contacts"
              >CONTACTS</a
            >
          </li>
        </ul>
        <ul class="nav navbar-nav flex-row justify-content-center flex-nowrap">
          <li class="nav-item nav-item">
            <router-link
              v-if="!customLetsTalk && !customBiography"
              to="/lets-talk"
              class="btn nav-link btn-lets__talk"
              >Let’s talk!</router-link
            >
            <router-link v-else to="/" class="btn-back">
              <i class="fa fa-arrow-left back-arrow"></i>Back</router-link
            >
            <div
              class="btn-lets__talk__ btn-lets__talk invisible anim-none"
              style="display:none"
            >
              Let’s talk!
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import Logo from "../Navbar/NavbarLogo.vue";

export default {
  name: "NavbarLogo",
  components: {
    Logo,
  },
  data: function() {
    return {
      customLetsTalk: this.isLetsTalk,
      customBiography: this.isBiography,
    };
  },
  props: ["isLetsTalk", "isBiography"],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.navbar-toggler {
  color: transparent !important;
  border-color: unset !important;
  padding: 0 !important;
}
.toggle-side {
  animation: fadein 1.5s;
  z-index: 31;
}
.bar {
  -webkit-transition: all 0.7s ease;
  -moz-transition: all 0.7s ease;
  -ms-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
}
.toggle-side .bar {
  position: absolute;
  display: block;
  width: 32px;
  height: 4px;
  background-color: black;
  transition: transform 0.3s;
}
.toggle-side.show #menu-btn-line-top {
  -webkit-transform: translateX(-400px);
  -moz-transform: translateX(-400px);
  -ms-transform: translateX(-400px);
  -o-transform: translateX(-400px);
  transform: translateX(-400px);
}
#menu-btn-line-middle_2 {
  opacity: 0.6;
}
.toggle-side.show #menu-btn-line-middle {
  transform: translate(5px, 26px) rotate(-45deg) scale(1.1);
  stroke: #ffffff !important;
}
.toggle-side.show #menu-btn-line-middle_2 {
  transform: translate(5px, 2px) rotate(45deg) scale(1.1);
  stroke: #ffffff !important;
  opacity: 1;
}
#menu-btn-line-bottom {
  transform: translateY(27px);
}
#menu-btn-line-middle,
#menu-btn-line-middle_2 {
  transform: translate(14px, 13.5px);
}
.toggle-side.show #menu-btn-line-bottom {
  -webkit-transform: translateX(-400px);
  -moz-transform: translateX(-400px);
  -ms-transform: translateX(-400px);
  -o-transform: translateX(-400px);
  transform: translateX(-400px);
}
.menu-item__insta a svg path :hover {
  color: #e1306c;
  transition: all 0.2s ease-in-out;
}
.menu-item__fb a svg path :hover {
  color: #4267b2;
  transition: all 0.2s ease-in-out;
}
.menu-item__linkedin a svg path :hover {
  color: #2867b2;
  transition: all 0.2s ease-in-out;
}
.btn-nav {
  float: right;
  margin-right: 45px;
}

.btn-nav div {
  line-height: 15px;
}

.my-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
  a {
    cursor: pointer;
    transition: all 0.5s;
  }
  .left-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.body-container {
  width: 100%;
  height: 100%;
  .side-menu {
    width: 100%;
    top: 0;
    position: fixed;
    background-color: rgb(197, 67, 66);
    right: -100%;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 30;
    .header {
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .side-menu.show {
    right: 0;
  }
}

.menu-close {
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 28px;
  padding: 10px;
}

.menu-title {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3em;
}
.menu-items {
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8vh;
}
.menu-item {
  z-index: 1;
  padding: 15px;
}

.menu-item__lets-talk {
  z-index: 1;
  color: #ffc65a;
  font-size: 23px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  animation: fadein 1.5s;
}

.menu-link {
  color: #fff;
  font-size: 30px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
}
.menu-items__socialmedia {
  padding-top: 5vh;
  z-index: 1;
  display: flex;
}
.menu-item__fb,
.menu-item__insta,
.menu-item__linkedin {
  padding: 15px;
}

.logo {
  width: 178px;
  height: 46px;
}
.nav-block {
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 0.45) 61%,
    rgba(255, 255, 255, 0.21) 81%,
    rgba(255, 255, 255, 0) 99%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 102%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 0.45) 61%,
    rgba(255, 255, 255, 0.21) 81%,
    rgba(255, 255, 255, 0) 99%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 102%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 0.45) 61%,
    rgba(255, 255, 255, 0.21) 81%,
    rgba(255, 255, 255, 0) 99%,
    rgba(255, 255, 255, 0) 100%,
    rgba(255, 255, 255, 0) 102%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 );
  z-index: 9;
  padding-top: 3em;
  padding-bottom: 3em;
  padding-left: 7em;
  padding-right: 7em;
  position: fixed;
  width: 100%;
}
.link__middle {
  padding-left: 12em !important;
  padding-right: 12em !important;
}
.link__text {
  fill: #212121 !important;
  font-size: 18px;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
}
.btn-lets__talk {
  color: #212121 !important;
  border-radius: 7px;
  padding: 9px;
  width: 150px;
  text-align: center;
  font-size: 18px;
  font-family: Poppins-Bold, Poppins;
  font-weight: 700;
  box-shadow: 5px 10px;
  background-color: #ffce00;
  -webkit-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  -moz-box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  box-shadow: 3px 6px 13px -3px rgba(0, 0, 0, 0.36);
  animation: jittery 4s infinite, fadein 1.5s;
}
.line-menu_resp {
  display: none;
}
.btn-back {
  color: #212121;
  font-size: 25px;
  font-family: Montserrat-Regular, Montserrat;
  text-decoration: none;
  animation: fadein 1.5s;
}
.back-arrow {
  padding-right: 10px;
}
.btn-back:hover {
  opacity: 0.9;
  .back-arrow {
    animation: arrow-back-bounce-- 3s infinite;
  }
}
@keyframes arrow-back-bounce-- {
  0% {
    transform: translateX(0px);
  }
  30% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(0px);
  }
}
.navbar-links li {
  display: inline-block;
  list-style: outside none none;
  padding: 0;
}
.navbar-links a {
  padding: 0.5em 0;
  color: rgba(255, 255, 255, 0.5);
  position: relative;
  letter-spacing: 1px;
  text-decoration: none;
}
.navbar-links a:before,
.navbar-links a:after {
  position: absolute;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.navbar-links a:before {
  bottom: 0;
  display: block;
  height: 3px;
  width: 0%;
  content: "";
  background-color: rgb(198, 67, 66);
}
.navbar-links a:after {
  left: 0;
  top: 0;
  padding: 0.5em 0;
  position: absolute;
  content: attr(data-hover);
  color: #ffffff;
  white-space: nowrap;
  max-width: 0%;
  overflow: hidden;
}
.navbar-links a:hover:before,
.navbar-links .current a:before {
  opacity: 1;
  width: 85%;
}
.navbar-links a:hover:after,
.navbar-links .current a:after {
  max-width: 100%;
}
.btn-hidden-lets__talk {
  cursor: default !important;
  opacity: 0;
  -webkit-animation: zoomout 0.5s !important;
  -moz-animation: zoomout 0.5s !important;
  animation: zoomout 0.5s !important;
}
@media only screen and (max-width: 1635px) {
  .link__middle {
    padding-left: 8em !important;
    padding-right: 8em !important;
  }
}

@media only screen and (max-width: 1375px) {
  .link__middle {
    padding-left: 6em !important;
    padding-right: 6em !important;
  }
  .nav-block {
    padding-left: 5em;
    padding-right: 5em;
  }
}

@media only screen and (max-width: 1200px) {
  .link__text {
    font-size: 16px;
  }
  .link__middle {
    padding-left: 4em !important;
    padding-right: 4em !important;
  }
  .nav-block {
    padding-left: 3em;
    padding-right: 3em;
  }
  .btn-lets__talk {
    width: 120px;
    font-size: 16px;
    height: 40px;
  }
  .logo {
    width: 148px;
  }
  .btn-back {
    font-size: 22px;
  }
}

@media only screen and (max-width: 992px) {
  .link__text {
    font-size: 14px;
  }
  .link__middle {
    padding-left: 3em !important;
    padding-right: 3em !important;
  }
  .nav-block {
    padding-left: 2em;
    padding-right: 2em;
  }
  .btn-lets__talk {
    width: 110px;
    font-size: 14px;
    height: 38px;
  }
  .logo {
    width: 138px;
  }
  .btn-back {
    font-size: 21px;
  }
}

@media only screen and (min-width: 768px) {
  .btn-back-resp {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .side-menu {
    display: none !important;
  }
  .body-container .side-menu.show {
    right: -100% !important;
  }
  .toggle-side {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .btn-back {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .navbar-links a:before {
    display: none;
  }
  .nav-block {
    padding: 1.5em;
  }
  .logo {
    padding-top: 5px;
  }
  .side-menu {
    display: block;
  }
  #bg-menu__logo {
    transform: scale(0.85) translate(60px, 60px);
  }
  .line-menu_resp {
    display: block;
  }
  .toggle-side {
    display: block !important;
  }
}
@media screen and (max-width: 550px) {
  .my-navbar .right-nav {
    width: 18px;
    overflow: hidden;
    display: inline-flex;
    margin-right: 10px;
  }
  .my-navbar .left-nav .logout {
    display: none;
  }
  .my-navbar .left-nav {
    width: auto;
  }
}
@media only screen and (min-height: 740px) and (max-height: 840px) and (max-width: 767px) {
  .menu-items {
    padding-top: 12vh !important;
  }
}
@media only screen and (max-height: 640px) and (max-width: 380px) {
  .menu-item__lets-talk {
    font-size: 20px !important;
  }
  .menu-link {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 670px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(20px, 60px);
  }
}
@media only screen and (max-width: 500px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(-60px, 60px);
  }
}
@media only screen and (max-width: 499px) {
  .menu-item__lets-talk {
    font-size: 25px;
  }
  .menu-link {
    font-size: 30px;
  }
}
@media only screen and (max-width: 450px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(-80px, 60px);
  }
}
@media only screen and (max-width: 400px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(-90px, 60px);
  }
}
@media only screen and (max-width: 350px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(-130px, 60px);
  }
}
@media only screen and (max-width: 320px) {
  .logo {
    width: 128px;
  }
  .nav-block {
    padding: 1em;
  }
}
@media only screen and (max-width: 310px) {
  #bg-menu__logo {
    transform: scale(0.85) translate(-150px, 60px);
  }
}
</style>
