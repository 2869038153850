<template>
<div>
  <a v-if="!customBtn" id="home" class="navbar-brand mr-0">
    <div class="logo">
      <svg viewBox="0 0 178.699 46">
        <g transform="translate(-162 -99)">
          <g transform="translate(23.625 15.548)">
            <g transform="translate(138.375 83.452)">
              <path
                style="fill: #c64342;"
                d="M214.167,91.837V83.452H172.322v33.539h25.107v-8.385H180.691V91.837Z"
                transform="translate(-168.167 -83.452)"
              />
              <path
                style="fill: #c64342;"
                d="M171.851,186.432H155.113v8.385h16.738v16.77H138.375v8.385H180.22V186.432Z"
                transform="translate(-138.375 -173.971)"
              />
            </g>
          </g>
          <g transform="translate(-83.889 -187)">
            <path
              style="fill: #212121;"
              d="M75.406,64.159a4.942,4.942,0,0,0-.734-.547,8.966,8.966,0,0,0-1.3-.677,11.233,11.233,0,0,0-1.656-.561,7.242,7.242,0,0,0-1.814-.231,4.067,4.067,0,0,0-2.447.6,2,2,0,0,0-.806,1.7,1.746,1.746,0,0,0,.3,1.051,2.61,2.61,0,0,0,.878.734,7.365,7.365,0,0,0,1.454.576q.878.259,2.03.547,1.5.4,2.721.864a7.384,7.384,0,0,1,2.073,1.152,4.761,4.761,0,0,1,1.31,1.656,5.5,5.5,0,0,1,.461,2.376,5.917,5.917,0,0,1-.619,2.807,5.207,5.207,0,0,1-1.67,1.886,7.258,7.258,0,0,1-2.419,1.051,12.159,12.159,0,0,1-2.865.331,15.387,15.387,0,0,1-4.55-.691,14.039,14.039,0,0,1-4.031-1.958L63.485,73.4a5.511,5.511,0,0,0,.936.677,14.184,14.184,0,0,0,1.6.835,12.393,12.393,0,0,0,2.045.705,9.223,9.223,0,0,0,2.3.288q3.282,0,3.282-2.1a1.725,1.725,0,0,0-.374-1.123,3.178,3.178,0,0,0-1.065-.806,10.208,10.208,0,0,0-1.67-.633q-.979-.287-2.188-.634A18.583,18.583,0,0,1,65.8,69.73,6.689,6.689,0,0,1,64,68.607a4.2,4.2,0,0,1-1.08-1.5,5.179,5.179,0,0,1-.36-2.03,6.351,6.351,0,0,1,.576-2.764,5.584,5.584,0,0,1,1.6-2.016,7.242,7.242,0,0,1,2.375-1.224,9.825,9.825,0,0,1,2.908-.418A11.292,11.292,0,0,1,74,59.336a14.816,14.816,0,0,1,3.167,1.6Z"
              transform="translate(246.273 240.341)"
            />
            <path
              style="fill: #212121;"
              d="M208,76.612V80.1h-14.2V59.652h13.936v3.484h-9.963V68.06h8.61v3.225h-8.61v5.327Z"
              transform="translate(133.343 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M303.592,59.652l5.039,9.848,5.125-9.848h4.29l-7.429,13.3V80.1h-3.945V72.9l-7.4-13.245Z"
              transform="translate(43.166 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M434.134,59.652l5.529,15.463,5.471-15.463h4.175L441.333,80.1h-3.34l-8.034-20.444Z"
              transform="translate(-68.574 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M574.784,79.391a9.285,9.285,0,0,1-4.074-.878,10,10,0,0,1-3.139-2.332,10.816,10.816,0,0,1-2.75-7.155,10.272,10.272,0,0,1,.763-3.916,10.809,10.809,0,0,1,2.1-3.311,10.034,10.034,0,0,1,3.182-2.289,9.448,9.448,0,0,1,4-.849,8.978,8.978,0,0,1,4.046.907,10.109,10.109,0,0,1,3.124,2.376,10.942,10.942,0,0,1,2.016,3.34,10.519,10.519,0,0,1,.72,3.8,10.339,10.339,0,0,1-.749,3.9,10.6,10.6,0,0,1-2.073,3.282,9.973,9.973,0,0,1-3.167,2.275A9.444,9.444,0,0,1,574.784,79.391Zm-5.932-10.366a8.215,8.215,0,0,0,.4,2.563,6.838,6.838,0,0,0,1.166,2.188,5.793,5.793,0,0,0,1.872,1.526,5.8,5.8,0,0,0,5.068-.014,5.8,5.8,0,0,0,1.872-1.569,7,7,0,0,0,1.152-2.2,8.179,8.179,0,0,0,.389-2.491,7.976,7.976,0,0,0-.418-2.563,7.077,7.077,0,0,0-1.181-2.188,5.638,5.638,0,0,0-1.872-1.512,5.831,5.831,0,0,0-5.053.029,5.683,5.683,0,0,0-1.872,1.555,6.981,6.981,0,0,0-1.137,2.188A8.185,8.185,0,0,0,568.852,69.025Z"
              transform="translate(-183.884 240.341)"
            />
            <path
              style="fill: #212121;"
              d="M740.187,80.1V66.563l-5.269,10.078h-2.16l-5.269-10.078V80.1h-3.974V59.652h4.261l6.047,11.633,6.1-11.633h4.233V80.1Z"
              transform="translate(-319.572 239.492)"
            />
          </g>
        </g>
      </svg>
    </div>
  </a>
  <a v-else href="/" class="navbar-brand mr-0">
    <div class="logo">
      <svg viewBox="0 0 178.699 46">
        <g transform="translate(-162 -99)">
          <g transform="translate(23.625 15.548)">
            <g transform="translate(138.375 83.452)">
              <path
                style="fill: #c64342;"
                d="M214.167,91.837V83.452H172.322v33.539h25.107v-8.385H180.691V91.837Z"
                transform="translate(-168.167 -83.452)"
              />
              <path
                style="fill: #c64342;"
                d="M171.851,186.432H155.113v8.385h16.738v16.77H138.375v8.385H180.22V186.432Z"
                transform="translate(-138.375 -173.971)"
              />
            </g>
          </g>
          <g transform="translate(-83.889 -187)">
            <path
              style="fill: #212121;"
              d="M75.406,64.159a4.942,4.942,0,0,0-.734-.547,8.966,8.966,0,0,0-1.3-.677,11.233,11.233,0,0,0-1.656-.561,7.242,7.242,0,0,0-1.814-.231,4.067,4.067,0,0,0-2.447.6,2,2,0,0,0-.806,1.7,1.746,1.746,0,0,0,.3,1.051,2.61,2.61,0,0,0,.878.734,7.365,7.365,0,0,0,1.454.576q.878.259,2.03.547,1.5.4,2.721.864a7.384,7.384,0,0,1,2.073,1.152,4.761,4.761,0,0,1,1.31,1.656,5.5,5.5,0,0,1,.461,2.376,5.917,5.917,0,0,1-.619,2.807,5.207,5.207,0,0,1-1.67,1.886,7.258,7.258,0,0,1-2.419,1.051,12.159,12.159,0,0,1-2.865.331,15.387,15.387,0,0,1-4.55-.691,14.039,14.039,0,0,1-4.031-1.958L63.485,73.4a5.511,5.511,0,0,0,.936.677,14.184,14.184,0,0,0,1.6.835,12.393,12.393,0,0,0,2.045.705,9.223,9.223,0,0,0,2.3.288q3.282,0,3.282-2.1a1.725,1.725,0,0,0-.374-1.123,3.178,3.178,0,0,0-1.065-.806,10.208,10.208,0,0,0-1.67-.633q-.979-.287-2.188-.634A18.583,18.583,0,0,1,65.8,69.73,6.689,6.689,0,0,1,64,68.607a4.2,4.2,0,0,1-1.08-1.5,5.179,5.179,0,0,1-.36-2.03,6.351,6.351,0,0,1,.576-2.764,5.584,5.584,0,0,1,1.6-2.016,7.242,7.242,0,0,1,2.375-1.224,9.825,9.825,0,0,1,2.908-.418A11.292,11.292,0,0,1,74,59.336a14.816,14.816,0,0,1,3.167,1.6Z"
              transform="translate(246.273 240.341)"
            />
            <path
              style="fill: #212121;"
              d="M208,76.612V80.1h-14.2V59.652h13.936v3.484h-9.963V68.06h8.61v3.225h-8.61v5.327Z"
              transform="translate(133.343 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M303.592,59.652l5.039,9.848,5.125-9.848h4.29l-7.429,13.3V80.1h-3.945V72.9l-7.4-13.245Z"
              transform="translate(43.166 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M434.134,59.652l5.529,15.463,5.471-15.463h4.175L441.333,80.1h-3.34l-8.034-20.444Z"
              transform="translate(-68.574 239.492)"
            />
            <path
              style="fill: #212121;"
              d="M574.784,79.391a9.285,9.285,0,0,1-4.074-.878,10,10,0,0,1-3.139-2.332,10.816,10.816,0,0,1-2.75-7.155,10.272,10.272,0,0,1,.763-3.916,10.809,10.809,0,0,1,2.1-3.311,10.034,10.034,0,0,1,3.182-2.289,9.448,9.448,0,0,1,4-.849,8.978,8.978,0,0,1,4.046.907,10.109,10.109,0,0,1,3.124,2.376,10.942,10.942,0,0,1,2.016,3.34,10.519,10.519,0,0,1,.72,3.8,10.339,10.339,0,0,1-.749,3.9,10.6,10.6,0,0,1-2.073,3.282,9.973,9.973,0,0,1-3.167,2.275A9.444,9.444,0,0,1,574.784,79.391Zm-5.932-10.366a8.215,8.215,0,0,0,.4,2.563,6.838,6.838,0,0,0,1.166,2.188,5.793,5.793,0,0,0,1.872,1.526,5.8,5.8,0,0,0,5.068-.014,5.8,5.8,0,0,0,1.872-1.569,7,7,0,0,0,1.152-2.2,8.179,8.179,0,0,0,.389-2.491,7.976,7.976,0,0,0-.418-2.563,7.077,7.077,0,0,0-1.181-2.188,5.638,5.638,0,0,0-1.872-1.512,5.831,5.831,0,0,0-5.053.029,5.683,5.683,0,0,0-1.872,1.555,6.981,6.981,0,0,0-1.137,2.188A8.185,8.185,0,0,0,568.852,69.025Z"
              transform="translate(-183.884 240.341)"
            />
            <path
              style="fill: #212121;"
              d="M740.187,80.1V66.563l-5.269,10.078h-2.16l-5.269-10.078V80.1h-3.974V59.652h4.261l6.047,11.633,6.1-11.633h4.233V80.1Z"
              transform="translate(-319.572 239.492)"
            />
          </g>
        </g>
      </svg>
    </div>
  </a>
  </div>
</template>

<script>
export default {
  name: "Logo",
    data: function() {
    return {
      customBtn: this.isCustomBtn,
    };
  },
  props: [
    'isCustomBtn'
  ]
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.logo {
  width: 178px;
  height: 46px;
}

@media only screen and (max-width: 1200px) {
  .logo {
    width: 148px;
  }
}

@media only screen and (max-width: 992px) {
  .logo {
    width: 138px;
  }
}
</style>
